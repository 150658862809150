import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../containers/SaasModern/Documentation';
import item_template_default from '../../../../../../common/src/assets/image/2019/item_template_default.png';
import item_template_custom from '../../../../../../common/src/assets/image/2019/item_template_custom.png';
import item_template_example from '../../../../../../common/src/assets/image/item_template_example.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal provides the ability to customize the item template that is
        used for displaying events and tasks in most of calendar templates.
        There are 5 fields that can be configured in any order. You can pick and
        choose from these fields, or remove them from the item template if there
        are things you never want to see in the output. In some cases, calendar
        template settings will also control whether these fields are visible,
        such as the calendar template setting for including locations.
      </p>
      <p>
        The field names are wrapped in [square brackets] in the item template.
        Additional static text can also be inserted into the template if
        desired.
      </p>
      <p>
        <Image alt="" src={item_template_default} style={{ width: 891 }} />
      </p>
      <ol className="numbered">
        <li>
          <strong>[Time]:</strong> The item time. The format of the time, and
          whether the end time is also shown, is determine in the calendar
          template settings when generating.
        </li>
        <li>
          <strong>[Calendar Prefix]:</strong> The prefix of the associated
          calendar data source. Calendar prefixes can be specified in the
          settings panel that appears when a calendar data source is selected.
        </li>
        <li>
          <strong>[Title]:</strong> The item title or subject.
        </li>
        <li>
          <strong>[Occurrence Number]: </strong>For items that follow a
          recurrence pattern, the sequential occurrence number of the item. For
          example, this could be a person's age for a yearly recurring birthday
          event. The first occurrence of a yearly event will have an occurrence
          number of 0. Monthly, weekly, and daily events will start with an
          occurrence number of 1.
        </li>
        <li>
          <strong>[Location]:</strong> The item's location.
        </li>
      </ol>
      <p>
        In addition to the ordering of fields, you can also specify additional
        text that should come before or after each value. For example, you might
        want the calendar prefix to be followed by a dash and the item location
        to be prefixed with a semicolon. A preview is shown to the right of each
        field to show how it will look. An example item is also shown at the
        bottom of the <strong>Item Template</strong> tab so you can see the
        overall effect of the specified template.
      </p>
      <p>
        The default template is
        <strong> [Time][Calendar Prefix][Title][Location]</strong>.This default
        was overridden in the example shown below. Occurrence numbers will come
        first, then titles followed by times in parentheses. Locations will not
        be shown. You can restore the default template by clicking the{' '}
        <strong>Restore Defaults</strong> button.
      </p>
      <p>
        <Image alt="" src={item_template_custom} style={{ width: 891 }} />
      </p>
      <p>
        Here's an example of how items will appear in generated output when
        using these settings:
      </p>
      <p>
        <Image alt="" src={item_template_example} style={{ width: 543 }} />
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Options/Item-Template"
      commentsId="commentsplus_post_1202_489"
      title="Item Template"
      description="PrintableCal provides the ability to customize the item template that is used for events and tasks in most of the templates. There are 5 fields that can be configured in any order."
      keywords="printable calendar, item template, event template, task template, note template, event title, customize calendar"
      content={content}
    />
  );
};

export default Documentation;
